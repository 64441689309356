// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$aprova-login-primary: mat.define-palette(mat.$indigo-palette);
$aprova-login-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$aprova-login-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$aprova-login-theme: mat.define-light-theme((color: (primary: $aprova-login-primary,
        accent: $aprova-login-accent,
        warn: $aprova-login-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($aprova-login-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #9ca3af;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Inter, "Helvetica Neue", sans-serif;
}

@layer base {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.btn-box-error {
  color: #808B98;
}

.app-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  width: 100%;
  height: 100dvh;

  &__title {
    font-family: Inter, serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #111827;
  }

  &__subtitle {
    font-family: Inter, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #808B98;
  }
}

.main-content-loader {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  width: 100%;
  height: calc(100% - 100px);
  background: #fff;
  z-index: 1000;

  &__title {
    font-family: Inter, serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #111827;
  }

  &__subtitle {
    font-family: Inter, serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #808B98;
  }
}

@media (orientation: portrait) and (min-width: 768px) and (min-height: 1024px) {
  .main-content-loader {
    width: calc(100% - 340px);
    height: 100%;
  }
}

@media (min-width: 1024px) {
  .main-content-loader {
    width: calc(100% - 340px);
    height: 100%;
  }
}

.border-color-google {
  border-color: #dadce0 !important;
}
